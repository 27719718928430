import React, {useState, useEffect} from 'react';
import { Modal, Button, Divider, Space, Input } from 'antd';
import { DeleteTwoTone, PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import '../styles/modalStyles.css'
const {confirm} = Modal


export const ModalComponent = ({setIsOpen, isOpen, content, setContent, allData, setAllData}) => {
  const [newItem, setNewItem] = useState(false)
  const [localContent, setLocalContent] = useState({})
  const [origContent, setOrigContent] = useState({})
  const [newItemVal, setNewItemVal] = useState('') 

  useEffect(() => {
    if(!localContent.data){
      setLocalContent(content)
      setOrigContent(content)
    }
  })

  const deleteList = () => {
    const i = localContent.index
    // db - change status to inactive
    const updatedAllData = allData.filter((_, index) => index !== i);
    setAllData(updatedAllData);
    setLocalContent({})
    setContent({})
    setIsOpen(false)
  }

  const handleCancel = () => {
    setIsOpen(false)
    setLocalContent({})
    setContent({})
  }
  const handleOk = () => {
    // put in ddb
    const {index} = content
    allData[index].content = localContent.data.content
    setAllData(allData)
    setNewItem(false)
    setNewItemVal('')
    setIsOpen(false)
    setLocalContent({})
    setContent({})
  }
  const deleteItem = (index) => {
    const updatedContent = {
      ...localContent,
      data: {
        ...localContent.data,
        content: localContent.data.content.filter((_, i) => i !== index),
      },
    }

    setLocalContent(updatedContent)
    setNewItem(false)
    setNewItemVal('')
  }
  const toggleItem = (index) => {
    const {status} = localContent.data.content[index]
    const updatedStatus = status === 'active' ? 'inactive' : 'active'
    const updatedContent = {
      ...localContent,
      data: {
        ...localContent.data,
        content: localContent.data.content.map((item, i) => 
          i === index 
            ? { ...item, status: updatedStatus }
            : item
        ),
      },
    };
    setLocalContent(updatedContent)
  }
  const createNewItem = () => {
    setNewItem(true)
  }
  const changesCheck = (action, type) => {
    if(origContent !== localContent){
      safetyCheck(action, type)
    } else {
      handleCancel()
    }
  }

  const safetyCheck = (action, type) => {
    confirm({
      title: `Vil du ${type}?`,
      icon: <ExclamationCircleFilled />,
      okText: 'Ja',
      okType: 'danger',
      cancelText: 'Nej',
      onOk() {
        action()
      },
      onCancel() {
        'pass'
      },
    });
  }
  const handleTyping = (e) => {
    setNewItemVal(e.target.value)
  }
  const addNewItem = () => {
    const updatedContent = {
      ...localContent,
      data: {
        ...localContent.data,
        content: [
          { text: newItemVal, status: 'active' },
          ...localContent.data.content,
        ],
      },
    };

    setLocalContent(updatedContent)
    setNewItem(false)
    setNewItemVal('')
  }

  return (
    <Modal open={isOpen} onOk={() => changesCheck(handleOk, 'gemme ændringer')} onCancel={() => changesCheck(handleCancel, 'annullere ændringer')} okText='Gem Ændringer'>
      {localContent.data &&
      <div id='modal'>
        <div>
          <h2>{localContent.data.title}
            <Button style={{marginLeft: '20px'}} shape="circle" icon={<DeleteTwoTone />} onClick={() => safetyCheck(deleteList, 'slette liste')}/>
          </h2>
        </div>
        <div>
        <Divider orientation="left" plain>
          {!newItem &&
            <Button type="primary" style={{marginRight: '10px'}} shape="circle" icon={<PlusOutlined />} onClick={() => createNewItem()}/>
          }
          Nyt punkt
        </Divider>
        {newItem &&
            <Space.Compact style={{ width: '100%', marginBottom: '20px' }}>
              <Input value={newItemVal} placeholder='Punkt text' onChange={(e) => handleTyping(e)}/>
              <Button type="primary" onClick={() => addNewItem()}>Submit</Button>
            </Space.Compact>
          }
          {localContent.data.content && localContent.data.content.map((item, i) => (
            <li key={'modal_' + i}>
              <span className={item.status == 'active' ? 'activeItem' : 'inactiveItem'} onClick={() => toggleItem(i)}>{item.text}</span>
              <DeleteTwoTone onClick={() => safetyCheck(() => deleteItem(i), 'slette punkt')} style={{'marginLeft': '20px'}}/>
            </li>
          ))}
        </div>
      </div>
      }
    </Modal>
  )
}