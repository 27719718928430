import { useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { UserContext } from './App';
import { checkLocalStorage } from './helpers';

const PR = ({children}) => {
  const navigate = useNavigate()
  const {user, setUser} = useContext(UserContext)
  useEffect(() => {
    if(!user){
      const authenticate = async () => {
        const auth = await checkLocalStorage()
        if(auth){
          await setUser(auth)
        } else {
          navigate("/", { replace: true })
        }
      }
      authenticate()
    }
  }, [user, setUser, navigate])
  if(user){
    return children
  }

}

export default PR