import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Divider, Space, Typography,  List, Tooltip, Input, Modal } from 'antd';
import { PlusOutlined, HolderOutlined } from '@ant-design/icons';
import { ModalComponent } from './modal';
import 'antd/dist/reset.css';
import { StrictModeDroppable } from '../helpers/dragz';
import { localData } from './helpers';
import sharedValues from '../shared.json'

const { Text } = Typography;
const {baseUrl, headers} = sharedValues
const { accessToken, refreshToken } = JSON.parse(localData)

const Lists = () => {
  const [data, setData] = useState([]);
  const [createNewList, setCreateNewList] = useState(false)
  const [newListVal, setNewListVal] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      if (data.length === 0) {
        let res = await getReq();
        setData(res);
      }
    };
  
    fetchData();
  }, [data]);

  const getReq = async () => {
    let returnItem = {}
    try {
      returnItem = await axios.get(`${baseUrl}/lists?accessToken=${accessToken}&refreshToken=${refreshToken}`, {headers: headers})
    } catch (error) {
      console.error(error);
    }
    return returnItem.data.content
  }

  const handleOnDragEnd = (result) => {
    const { destination, source } = result;

    // If dropped outside the list
    if (!destination) return;
    const items = Array.from(data);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    // TODO api req post
    setData(items);
  };

  const newList = () => {
    setCreateNewList(true)
  }
  const handleOpenItem = async (e, data, index) => {
    console.log('view', data, index)
    setModalContent({'data':data, 'index':index})
    setIsModalOpen(true)
  }

  const addNewList = () => {
    // put in ddb
    const newItem = [{'id':'yo', 'title':newListVal, 'content': ''}]
    let newArray = [...newItem, ...data]
    setData(newArray)
    setCreateNewList(false)
    setNewListVal('')
  }

  const handleTyping = (e, item) => {
    setNewListVal(e.target.value)
  }

  return (
    <div id='test' className='fullWidth'>
        <ModalComponent content={modalContent} isOpen={isModalOpen} setIsOpen={setIsModalOpen} setContent={setModalContent} allData={data} setAllData={setData} />
        <Divider orientation="left" plain>
          {!createNewList &&
            <Button type="primary" style={{marginRight: '10px'}} shape="circle" icon={<PlusOutlined />} onClick={() => newList()}/>
          }
          Ny Liste
        </Divider>
          {createNewList != false &&
              <Space.Compact style={{ width: '100%', marginBottom: '20px' }}>
                <Input value={newListVal} placeholder='List name' onChange={(e) => handleTyping(e)}/>
                <Button type="primary" onClick={() => addNewList()}>Submit</Button>
              </Space.Compact>
          }
          {data ?
          <DragDropContext onDragEnd={handleOnDragEnd}>
          <StrictModeDroppable droppableId="droppable-list">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <List
                  bordered
                  className="fullWidth noBorder"
                  dataSource={data}
                  renderItem={(item, index) => (
                    <Draggable key={index + '-button'} draggableId={index + '_id' } index={index} >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            backgroundColor: snapshot.isDragging ? "#f0f0f0" : "#fff",
                            padding: "8px",
                            marginBottom: "4px",
                            borderRadius: "4px",
                            border: snapshot.isDragging
                              ? "2px solid #1890ff"
                              : "1px solid #d9d9d9",
                          }}
                        >
                          <List.Item onClick={(e) => handleOpenItem(e, item, index)}>
                            <List.Item.Meta
                              title={item.meta.title}
                            />
                            <Button icon={<HolderOutlined />} />
                          </List.Item>
                        </div>
                      )}
                    </Draggable>
                  )}
                />
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
        :
        <Text>You have no lists</Text>
        }
          
    </div>
  )
}

export default Lists