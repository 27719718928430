import React from 'react';

const Notes = () => {
  return (
    <div>
      <h1>Noter</h1>
    </div>
  )
}

export default Notes