import React from 'react';

const Recipes = () => {
  return (
    <div>
      <h1>Opskrifter</h1>
    </div>
  )
}

export default Recipes