import React, {useState, useContext, useEffect} from 'react';
import { Button, Input, Form, Checkbox, Flex, Spin, Alert } from 'antd';
import logo from '../images/logo.png';
import { checkLocalStorage } from './helpers';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { UserContext } from './App';
import sharedValues from '../shared.json'

const Front = (props) => {
  const {user, setUser} = useContext(UserContext)
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false)
  const [errorMessage, setErrorMessage] = useState({})
  const {headers, baseUrl, STTName} = sharedValues
  const navigate = useNavigate()

  useEffect(() => {
    if(!user){
      const authenticate = async () => {
        await setLoading(true)
        const auth = await checkLocalStorage()
        await setUser(auth)
        await setAuthenticated(true)
        await setLoading(false)
      }
      authenticate()
    } else {
      setLoading(false)
    }


  }, [user, setUser])

  const handleSignIn = async (data) => {
    const {email, password, remember} = data
    try{
      let signin = await axios.post(`${baseUrl}/signin`, {email, password}, headers)

      if(signin.data.message !== 'ok'){
        setErrorMessage({
          signin:{
            message: signin.data.message.message,
            type: signin.data.message.type
          }
        })
      } else {
        await setUser({user:{
          username: signin.data.username
        }})
        if(remember){
          await localStorage.setItem(STTName, JSON.stringify({
            accessToken: signin.data.access_token,
            refreshToken: signin.data.refresh_token,
          }))
        }
        await navigate('/', { replace: true })
      }
    }
    catch(e){
      setErrorMessage({
        signin:{
          message:'Wrong user and/or password, try again!',
          type: 'error'
        }
      })
    }
  }
  
  const resetErrorMessage = () => {
    setErrorMessage({})
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='centerContent'>
      {errorMessage.signin &&
        <Alert
        description={errorMessage.signin.message}
        type={errorMessage.signin.type}
        showIcon
        closable
        afterClose={resetErrorMessage}/>
      }
      <img className='logoStyle' src={logo} alt='logo'/>
      {loading ?
        <Flex align="center" gap="middle" style={{marginTop:'50px'}}>
          <Spin size="large" />
        </Flex>
      : !user && isAuthenticated ? 
        <div id='signInWrapper'>
          <Form
            name="login"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={handleSignIn}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please type username!' }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please type password!' }]}>
              <Input.Password />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      :
      null
    }
    </div>
  )
}

export default Front
