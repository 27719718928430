import React, { useState, createContext } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Front from './front'
import Budget from './budget'
import Foodplan from './foodplan'
import TopNav from './topNav'
import Calender from './calender'
import Notes from './notes';
import Lists from './lists';
import Recipes from './recipes';
import PR from './lockedRoute'
import { Layout, theme } from 'antd';
const { Footer, Content } = Layout;

export const UserContext = createContext(null);
// test
const App = () => {
  const [user, setUser] = useState(null);
  const {token: { colorBgContainer, borderRadiusLG }} = theme.useToken();

  return(
    <BrowserRouter>
      <UserContext.Provider value={{ user, setUser }}>
        <Layout>
          <TopNav />
          <Content className='mobileMain'>
          <div
            style={{
              display: 'flex',
              background: colorBgContainer,
              justifyContent: 'center',
              minHeight: '90vh',
              padding: 24,
              borderRadius: borderRadiusLG,
            }}
          >
            <Routes>
              <Route path="/" element={<Front />} />
              <Route path="/budget" element={<PR><Budget /></PR>} />
              <Route path="/foodplan" element={<PR><Foodplan /></PR>} />
              <Route path="/calender" element={<PR><Calender /></PR>} />
              <Route path="/notes" element={<PR><Notes /></PR>} />
              <Route path="/lists" element={<PR><Lists /></PR>} />
              <Route path="/recipes" element={<PR><Recipes /></PR>} />
            </Routes>
          </div>
          </Content>
          <Footer/>
        </Layout>
      </UserContext.Provider>
    </BrowserRouter>
  )  
}
export default App