import axios from 'axios';
import sharedValues from '../shared.json';

export const localData = localStorage.getItem(sharedValues.STTName);

export const checkLocalStorage = async () => {
    const { headers, baseUrl } = sharedValues;
    const storedData = localData
    if (!storedData) return null

    const { accessToken, refreshToken } = JSON.parse(storedData)
    if (!accessToken) return null

    try {
        const response = await axios.post(`${baseUrl}/signin`,
            { accessToken, refreshToken },
            { headers }
        )
        const { message, username } = response.data
        
        if (message === 'error') {
            return null
        } else {
            return { username }
        }
    } catch (error) {
        console.error('Error during sign-in:', error)
        return null
    }
}