import React from 'react';

const Calender = () => {
  return (
    <div>
      <h1>Kalender</h1>
    </div>
  )
}
// https://github.com/facebook/lexical
export default Calender