import React, {useState, useEffect, useContext} from 'react';
import { Select, Space, Flex, Spin, Button, Input, InputNumber, Table } from 'antd';
import { UserContext } from './App';
const months = [{value:'1'}, {value:'2'}, {value:'3'}, {value:'4'}, {value:'5'}, {value:'6'}, {value:'7'}, {value:'8'}, {value:'9'}, {value:'10'}, {value:'11'}, {value:'12'}]
const columns = [
  {title: 'Amount', dataIndex: 'amount'},
  {title: 'Item', dataIndex: 'label'},
  {title: 'Type', dataIndex: 'type'},
  {title: 'Note', dataIndex: 'note'},
]
const dummyProfiles = {
  '123':{
    id:'123', // this will be the pk
    name:'HouseHold',
    owner: 'uuid-123',
    canAccess:['123', '456'],
    sharedWith:['123'],
    expense:[],
    income:[]
  },
  '333':{
    id: '333', // this will be the pk
    name:'Emillos personal',
    owner: 'uuid-333',
    canAccess:['123', '456'],
    sharedWith:['123'],
    expense:[
      {label:'Github', amount:'78', type:'Abonoment', note:'Nice to have'},
      {label:'Phone', amount:'123', type:'Abonoment', note:'Need to have'}
    ],
    income:[
      {label:'Wage', amount:'35000', type:'Monthly', note:'Novo Nordisk'},
      {label:'Bonus', amount:'65000', type:'Yearly', note:'Novo Nordisk'}
    ]
  }
}

const userProfile = {
  pk: '222554e4-d051-705e-ba3b-5ddddc48f0b2',
  sk: 'user:profile',
  email: 'filtenborgweb@gmail.com',
  userName: 'Emillos',
  settings:{
    budget:{
      availAccounts: [
        {id:'333', name:'Emillos personal'}, 
        {id:'123', name:'HouseHold'}
      ],
      budgetDefault:'333'
    }
  }
 }

const Budget = () => {
  //const {user, setUser} = useContext(UserContext)
  const [user, setUser] = useState({})
  const [accounts, setAccount] = useState({})
  const [activeAccount, setActiveAccount] = useState({})
  const [action, setAction] = useState('')

  useEffect(() => {
    const setValues = async () => {
      if(!user.userName){
        await setUser(userProfile) // get from userContext
      }
      const accountDetails = dummyProfiles['333'] // request - use settings from user
      if(user.userName && !accounts[accountDetails.id]){
        let createAvailAccounts = {}
        user.settings.budget.availAccounts.forEach(el => {
          createAvailAccounts[el.id] = el
        })
        await setAccount(createAvailAccounts) 
      }
      if(user.userName && !activeAccount.id){
        const fetchActiveAccount = user.settings.budget.budgetDefault
        await setActiveAccount(dummyProfiles[fetchActiveAccount])
      }
    }
    setValues()
  })

  const handleOnChange = (value, label) => {
    console.log(`selected ${value}`);
  };

  const renderOptions = (obj) => {
    let optionsArr = []
    for (const key in obj) {
      optionsArr.push({value:obj[key].id, label:obj[key].name})
    }
    return optionsArr
  }

  const handleButtonClick = (action) => {
    let NewA = action != 'close' ? action : ''
    setAction(NewA)
  }

  return (
    <div>
      {!user.settings?.budget ?
        <div>you have not yet set up a budget</div>  
      : activeAccount.id ?
      <div>
        <label>Active account:</label>
        <Space wrap>
          <Select
            defaultValue={activeAccount.name}
            style={{ width: 200, marginLeft: 10 }}
            onChange={handleOnChange}
            options={renderOptions(accounts)}
          />
        </Space>
        {!action &&
          // panel to initate create new item
          <div className='elementOfInterest actionPanel'>
            <Button onClick={() => handleButtonClick('income')}>+ Indtægt</Button>
            <Button onClick={() => handleButtonClick('expense')}>+ Udgift</Button>
          </div>
        }
        {action &&
          // create new item
          <Flex wrap className='elementOfInterest'>
            <Button danger type="text" onClick={() => handleButtonClick('close')}>
              Close Panel
            </Button>
            <div>
              <label>Name</label>
              <br />
              <Input placeholder="Name" style={{width:200}}/>
            </div>
            <div>
              <label>Amount</label>
              <br />
              <InputNumber placeholder="Amount" />
            </div>
            <Space wrap>
              <label>Every</label>
              <Select
                defaultValue={1}
                style={{ width: 60, marginLeft: 10 }}
                onChange={handleOnChange}
                options={months}
              />
              <label>Months</label>
            </Space>
            <Button type="primary">Add {action}</Button>
          </Flex>
        }
        <div className='total'>
          <h4>Total</h4>
        </div>
        <div className='income'>
          <h4>Indtægter</h4>
          <Table columns={columns} dataSource={activeAccount.income} pagination={false} bordered/>
        </div>
        <hr />
        <div className='expense'>
          <h4>Udgifter</h4>
          <Table columns={columns} dataSource={activeAccount.expense} pagination={false} bordered/>
        </div>
      </div>
      :
      <Flex align="center" gap="middle" style={{marginTop:'50px'}}>
        <Spin size="large" />
      </Flex>
    }
    </div>
  )
}

export default Budget