import React, {useContext} from "react";
import { Layout, Menu } from 'antd';
import { Link, useLocation } from "react-router-dom";
import logo from '../images/logo.png';
import { useNavigate } from "react-router-dom";
import sharedValues from '../shared.json'
import { UserContext } from './App';
const { Header } = Layout;

const TopNav = () => {
  const {user, setUser} = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()

  const signOut = async () => {
    const {STTName} = sharedValues
    await localStorage.removeItem(STTName)
    await setUser(null)
    await navigate('/', { replace: true })
  }

  const items = [
    /* {
      label:<Link to='/notes'>Noter</Link>, 
      key: 'notes'
    }, */
    {
      label:<Link to='/lists'>Lister</Link>, 
      key: 'lists'
    },
    /* {
      label:<Link to='/recipes'>Opskrifter</Link>, 
      key: 'recipes'
    },
    {
      label:<Link to='/foodplan'>Madplan</Link>, 
      key:'foodPlan'
    },
    {
      label:<Link to='/calender'>Kalender</Link>, 
      key:'calender'
    }, */
    {
      label: <a href='#' onClick={() => signOut()}>Log af</a>,
      key: 'SignOut'
    }
  ]

  return (
    <div>
      <Header
          style={{
            display: 'flex',
            alignItems: 'center',
            background:'#BF5B04',
          }}
        >
          {location.pathname !== '/' &&
            <Link className='imageLink' to='/'>
              <img className='logoStyle smallLogo' src={logo} alt='logo'/>
            </Link>
          }
          {user &&
            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={['2']}
              items={items}
              style={{
                background:'#BF5B04',
                flex: 1,
                minWidth: 0,
              }}
            />
          }
        </Header>
    </div>
  )
}

export default TopNav